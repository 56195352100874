import Swal from "sweetalert2";
let bootstrap = require('bootstrap');
import phoneMask from "../phone-mask";

phoneMask();

export class FeedbackModal {
    modalId = 'feedback-modal';
    formId = 'feedback-form-modal';

    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.feedbackFormModal = document.getElementById(this.formId);

            if (!this.feedbackFormModal) {
                return;
            }
            this.feedbackFormLabel = this.feedbackFormModal.querySelector('.box__input label');
            this.feedbackFormFileInput = this.feedbackFormModal.querySelector('.box__file');

            this.feedbackFormFileInput.onchange = (e) => {
                this.showFiles(e.target.files);
            };
            this.feedbackFormModal.onsubmit = this.submitFeedbackFormModal();

            if (this.isAdvancedUpload()) {
                const box = this.feedbackFormModal.querySelector('.box');
                let droppedFiles = false;
                const events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'];

                box.classList.add('has-advanced-upload');

                events.forEach(event => {
                    box.addEventListener(event, function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    });
                });
                box.addEventListener('dragover', function () {
                    box.classList.add('is-dragover');
                });
                box.addEventListener('dragenter', function () {
                    box.classList.add('is-dragover');
                });
                box.addEventListener('dragleave', function () {
                    box.classList.remove('is-dragover');
                });
                box.addEventListener('dragend', function () {
                    box.classList.remove('is-dragover');
                });
                box.addEventListener('drop', (e) => {
                    box.classList.remove('is-dragover');
                    droppedFiles = e.dataTransfer.files;
                    this.feedbackFormFileInput.files = droppedFiles;
                    this.showFiles(droppedFiles);
                });
            }
        });
    }

    isAdvancedUpload() {
        const div = document.createElement('div');
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    }

    submitFeedbackFormModal() {
        return (evt) => {
            evt.preventDefault();
            let fileSizeSum = 0;

            for (let i = 0; i < this.feedbackFormFileInput.files.length; i++) {
                let file = this.feedbackFormFileInput.files.item(i);
                fileSizeSum += file.size;
            }
            const box = this.feedbackFormModal.querySelector('.box');

            if (fileSizeSum >= 26214400) {
                box.classList.add('box__invalid-info');
                return;
            }
            box.classList.remove('box__invalid-info');

            if (!this.feedbackFormModal.checkValidity()) {
                this.feedbackFormModal.classList.add('was-validated')
                return;
            }
            const url = this.feedbackFormModal.getAttribute('action');
            let recaptchaPublicKey = this.feedbackFormModal.dataset.grecaptchaPublicKey;

            grecaptcha.ready(() => {
                grecaptcha.execute(recaptchaPublicKey, {action: 'submit'}).then(async (token) => {
                    this.feedbackFormModal.querySelector("input[name='grecaptcha_token']").value = token;
                    this.feedbackFormModal.querySelector('[name="phone"]').value = window.phoneInputWithPlaceholder.getNumber();
                    const formData = new FormData(this.feedbackFormModal);

                    try {
                        const response = await fetch(url, {
                            method: 'POST',
                            body: formData
                        });
                        const result = await response.json();

                        if (result.result === "success") {
                            Swal.fire({
                                icon: 'success',
                                title: 'Сообщение успешно отправлено',
                                showConfirmButton: false,
                                timer: 1500
                            });

                            this.feedbackFormModal.reset();
                            this.showFiles(this.feedbackFormFileInput.files);

                            let feedbackModal = document.getElementById(this.modalId);
                            let feedbackBootstrapModal = bootstrap.Modal.getInstance(feedbackModal);

                            feedbackBootstrapModal.hide();
                        } else if (result.result === "error") {
                            let message = "При отправке возникли следующие ошибки: \n";

                            result.errors.forEach(error => {
                                message += "- " + error + "\n";
                            });
                            alert(message);
                        }
                    } catch (error) {
                        console.error('Ошибка:', error);
                    }
                });
            });
        };
    }

    showFiles(files) {
        if (files.length === 0) {
            const box = this.feedbackFormModal.querySelector('.box');
            this.feedbackFormLabel.innerHTML = this.feedbackFormLabel.dataset.originText;

            box.classList.remove('box__invalid-info');
        } else if (files.length > 1) {
            this.feedbackFormLabel.innerText =  files.length + " " + this.declOfNum(files.length, ['файл', 'файла', 'файлов']) + this.declOfNum(files.length, [' загружен', ' загружено', ' загружено']);
        } else {
            this.feedbackFormLabel.innerText = files[0].name;
        }
    }

    declOfNum(number, titles) {
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    }
}
