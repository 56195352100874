import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';
import IMask from 'imask';

export default function phoneMask() {
    document.addEventListener('DOMContentLoaded', function () {
        const phoneInput = document.querySelector("#register-phone");

        if (phoneInput.parentNode.classList.contains('iti')) {
            return;
        }

        const mask = IMask(phoneInput, {
            mask: '000 000-00-00'
        });

        window.phoneInputWithPlaceholder = intlTelInput(phoneInput, {
            separateDialCode: true,
            initialCountry: "auto",
            geoIpLookup: async function (callback) {
                const request = await fetch("https://ipinfo.io", {
                    headers: {
                        'Accept': 'application/json'
                    },
                });
                const jsonResponse = await request.json();
                const countryCode = (jsonResponse && jsonResponse.country) ? jsonResponse.country : "ru";

                callback(countryCode);
            },
            utilsScript: utilsScript
        });

        phoneInput.addEventListener("countrychange", function() {
            let placeholder = phoneInput.getAttribute('placeholder');

            mask.updateOptions({
                mask: placeholder.replace(/[0-9]/g, "0")
            });
        });
    });
}

phoneMask();

