import '@lottiefiles/lottie-player';
import Typed from 'typed.js';
import {FeedbackModal} from '../components/feedback-modal';

const feedbackModal = new FeedbackModal();

async function getAnimation(url) {
    try {
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        console.error('Ошибка:', error);
    }
}

const animationWriter = getAnimation('/js/index/lf20_qurwzmzx.json');
const animationDesigner = getAnimation('/js/index/lf20_ffmxleob.json');
const animationMarketer = getAnimation('/js/index/lf20_4clprmsf.json');

window.onload = function () {
    let jivoBtns = document.querySelectorAll('.jivo__btn');

    if (jivoBtns.length > 0) {
        jivoBtns.forEach(btn => {
            btn.onclick = function () {
                jivo_api.open();
                yaCounter1838947.reachGoal('jivosite');
                return true;
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    console.log('DOMContentLoaded (index page)');

    var options = {
        strings: ['Мысли в профессиональном исполнении'],
        typeSpeed: 75,
        loop: true,
        backDelay: 5000,
    };
    var typed = new Typed('.main-index-jumbo__cursor-text', options);

    const mainIndexLottie = {
        animations: [animationWriter, animationDesigner, animationMarketer],
        counter: 0,
    }
    const mainIndexLottiePlayer = document.getElementById('main-index-lottie-player');

    if (mainIndexLottiePlayer) {
        console.log('mainIndexLottiePlayer element loaded');
        mainIndexLottie.animations[mainIndexLottie.counter].then(res => {
            mainIndexLottiePlayer.load(res);
            document.getElementById('loading-spinner').classList.add('d-none');
        });

        mainIndexLottiePlayer.addEventListener("frame", (evt) => {
            if (Math.floor(mainIndexLottiePlayer.getLottie().currentFrame) === 50) {
                if (mainIndexLottie.counter < 2) {
                    mainIndexLottie.counter++;
                } else {
                    mainIndexLottie.counter = 0;
                }
                mainIndexLottiePlayer.pause();
                setTimeout(() => {
                    mainIndexLottie.animations[mainIndexLottie.counter].then(res => {
                        mainIndexLottiePlayer.load(res);
                    });
                }, 4000);
            }
        });
    }
});

